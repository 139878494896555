<template>
    <div>
      <button @click="changeChart()" class="btn btn-success btn-sm mb-3" > 
        {{ change_chart ?  $t("Kesim Fire Grafiği") : $t("Ekip Kesim Fire Grafiği") }}
      </button>
      <p class="endorsement-title mb-0 pb-0 d-flex mx-3">
        {{  change_chart ? 'Ekip Kesim Fire Oranı' : 'Kesim Fire Oranı'}}
        <button-with-icon
          v-if="resizeBool == null || resizeBool == false"
          icon-name="icons.report.enlarge"
          class="button-open-close"
          size="sm"
          @onClick="resize()"
        ></button-with-icon>
        <button-with-icon
          v-if="resizeBool == true"
          icon-name="icons.report.reduce"
          class="button-open-close"
          size="sm"
          @onClick="resize()"
        ></button-with-icon>
      </p>
      <div
        class="row loading-area text-center justify-content-center align-items-center"
        v-if="chartLoading"
      >
        <div class="spinner-border text-secondary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div
        v-if="!chartLoading  &&  !change_chart"
        v-on:click="resizeBool ? null : resize()"
        class="mt-0 pt-0"
      >
        <apexchart
          type="pie"
          :height="height"
          :options="chartOptions"
          :series="series"
          :width="tmpWidth"
        ></apexchart>
      </div>
      <div
        v-if="!chartLoading && change_chart"
        v-on:click="resizeBool ? null : resize()"
        class="mt-0 pt-0"
      >
        <apexchart
          type="bar"
          :height="height"
          :options="chartOptionsBar"
          :series="seriesTeamBar"
          :width="tmpWidth"
        ></apexchart>
      </div>
    </div>
  </template>
  <script>
  import { GET_ITEMS } from "@/core/services/store/REST.module";
  import VueApexCharts from "vue-apexcharts";
  import { mapGetters } from "vuex";
  import { LARAVEL_DATE_FORMAT } from "@/core/config/constant";
  import * as _ from "lodash";
  import moment from "moment";
  import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
  export default {
    name: "SlaughterPieChartReport",
    components: {
      apexchart: VueApexCharts,
      ButtonWithIcon,
    },
    data() {
      return {
        chartCategories: [],
        chartCategoriesBar: [],
        resizeBool: null,
        tmpSeries: [],
        series: [],
        seriesTeamBar: [],
        labels: [],
        height: 300,
        tmpWidth: null,
        chartLoading: false,
        change_chart:false
      };
    },
  
    props: ["startDate", "endDate", "onClickButton", "filters"],
    mounted() {
      this.getItems();
    },
    computed: {
      chartOptions() {
        let self = this;
        if (this.resizeBool) {
          this.tmpWidth = window.innerWidth / 2;
        } else {
          this.tmpWidth = window.innerWidth / 3;
        }
        let chartOptions = {
          chart: {
            type: "donut",
          },
          colors: ["#1FE757", "#F8A314", "#FD1515"],
          title: {
            style: {
              fontSize: "13px",
              fontWeight: "bold",
              color: "#5cb7e0",
            },
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
          labels: ["Planlanan Gramaj", "Planlanan Gramaj Altında", "Planlanan Gramaj Üstünde"],
          legend: {
            position: "right",
            offsetY: 0,
            height: 300,
          },
        };
        return chartOptions;
      },
      chartOptionsBar() {
        let tmpChartOptions = {
          chart: {
            type: "bar",
            height: 400,
            stacked: true,
            stackType: "100%",
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          stroke: {
            width: 1,
            colors: ["#fff"],
          },
          title: {
            text: "",
            style: {
              fontSize: "13px",
              fontWeight: "bold",
              color: "#5cb7e0",
            },
          },
          xaxis: {
            categories: this.chartCategoriesBar,
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " Adet";
              },
            },
          },
          fill: {
            opacity: 1,
          },
          legend: {
            position: "top",
            horizontalAlign: "left",
            offsetX: 40,
            onItemClick: {
              toggleDataSeries: false,
            },
          },
        };
        return tmpChartOptions;
      },
    },
  
    methods: {
      changeChart(){
        this.change_chart = !this.change_chart
      }, 
      resize() {
        this.resizeBool = !this.resizeBool;
        if (this.resizeBool) {
          this.height = 400;
          this.tmpWidth = window.innerWidth / 2;
          this.$emit("resizecard", "piechart");
        } else {
          this.height = 300;
          this.tmpWidth = window.innerWidth / 3;
          this.$emit("resizecard", null);
        }
      },
      getItems() {
        this.chartLoading = true;
        this.series = [];
        this.chartCategories = []; 
        let filters = this.filters;
        this.$set(
          filters,
          "start_date",
          moment(this.startDate).format(LARAVEL_DATE_FORMAT)
        );
        this.$set(
          filters,
          "end_date",
          moment(this.endDate).format(LARAVEL_DATE_FORMAT)
        );
  
        this.$store
          .dispatch(GET_ITEMS, {
            filters: filters,
            url: "api/report/slaughter-pie-chart-report",
          })
          .then((result) => {
            this.chartLoading = false;
            if (result.status) {
              let tmpData = result.data.series; 
              this.series = [
                tmpData.planlanan_gram_esit,
                tmpData.planlanan_gram_alti,
                tmpData.planlanan_gram_ustu,
              ];
  

              //Teams
              let tmpDataTeamBar = result.data.team_bar_datas;
              let planlananGramajData = [];
              let planlananGramajAltındaData = [];
              let planlananGramajUstundeData = [];
              this.chartCategoriesBar = [];
              tmpDataTeamBar.forEach((item, key) => {
                planlananGramajData.push(item.planlanan_gram_esit);
                planlananGramajAltındaData.push(item.planlanan_gram_alti);
                planlananGramajUstundeData.push(item.planlanan_gram_ustu);
                this.chartCategoriesBar.push(item.team_name);
              });  
              this.seriesTeamBar = [
                {
                  name: "Planlanan Gramaj",
                  data: planlananGramajData,
                  color: "#1FE757",
                },
                {
                  name: "Planlanan Gramaj Altında",
                  data: planlananGramajAltındaData,
                  color: "#F8A314",
                },                
                {
                  name: "Planlanan Gramaj Üstünde",
                  data:  planlananGramajUstundeData,
                  color: "#FD1515",
                },
              ];
            } else {
              let tmpresult = result.data.response.data;
              this.sweetAlertError(tmpresult.message);
            }
          });
      },
    },
    watch: {
      onClickButton: function (newVal, oldVal) {
        if (newVal == true) {
          if (this.chartLoading != true) {
            this.series = [];
            this.labels = [];
            this.getItems();
          }
        }
      },
    },
  };
  </script>
  <style scoped>
  .endorsement-title {
    align-items: center;
    font-size: 14px;
    font-weight: 800;
    text-align: center;
    margin-top: 5px;
    color: #5cb7e0;
    justify-content: space-between;
  }
  .button-open-close {
    border: 0px !important;
    width: 20px;
  }
  .loading-area {
    height: 200px;
  }
  </style>
  