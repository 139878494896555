<template>
  <div class="row">
    <div class="col-md-12">
      <div id="filter-card" class="row py-3 px-4">
        <div class="col-lg-12 col-xl-12 px-0">
          <form @submit.prevent="filterResult()">
            <div class="row align-items-end py-0 my-0 mx-0" v-if="filters">
              <div class="col-xl-3 col-lg-2 col-md-2 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :model.sync="team_id"
                  :not-list="false"
                  :options="teamList"
                  :title="$t('scope.Team') + ':'"
                  :required="false"
                  :showInvalid="false"
                  :hasBottomMargin="false" 
                  input-max-width="125px"
                  input-width="150px"
                  :isOpenMultiselect="true"
                ></custom-multi-select>
              </div>
              <div class="col-xl-3 col-lg-2 col-md-2 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :item-per-row="1"
                  :model.sync="company_id"
                  :not-list="false"
                  :options="customerOptions"
                  :title="$t('general_report.customer') + ':'"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  :required="false"
                  input-max-width="200px"
                  input-width="100%"
                  name="order_company_id"
                ></custom-multi-select>
              </div>              
              <div class="col-xl-3 col-lg-2 col-md-2 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :item-per-row="1"
                  :model.sync="represantative_id"
                  :not-list="false"
                  :options="representativeList"
                  :title="$t('general_report.representative') + ':'"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  :required="false"
                  input-max-width="200px"
                  input-width="100%"
                  name="order_representative_id"
                  :isOpenMultiselect="true"
                ></custom-multi-select>
              </div> 
              <div class="col-xl-3 col-lg-2 col-md-2 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :model.sync="order_id"
                  :not-list="false"
                  :options="orderOptions"
                  :title="$t('report.order_no') + ':'"
                  :required="false"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  input-width="200px"
                  name="order_id"
                ></custom-multi-select>
              </div>
            </div>
            <div class="row align-items-end py-0 my-0 mx-0">
              <div class="col-lg-4 col-md-6 my-lg-2 my-sm-0 row mx-0 px-0">
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                    :helper-text="$t('general.start_date')"
                    :model.sync="filters.start_date"
                    name="start_time"
                    title="general.start_date"
                  >
                  </date-filter>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                    :helper-text="$t('general.end_date')"
                    :model.sync="filters.end_date"
                    name="end_date"
                    title="general.end_date"
                  >
                  </date-filter>
                </div>
              </div>
              <div
                class="col-xl-1 col-lg-1 col-md-1 col-sm-12 mt-3 px-1 d-flex align-items-center justify-content-end m-0 pr-0 row"
                style="margin: 0 !important"
              >
                <div class="col-12 pr-0">
                  <button
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1"
                    @click.prevent="filterResult()"
                  >
                    {{ $t("general.filter") }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1"
                    @click="resetFilters"
                  >
                    {{ $t("general.clear") }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="row">
        <div
          :class="[resizeChartValue == 'piechart' ? 'col-12' : 'col-6']"
          v-if="resizeChartValue != 'linechart'"
        >
          <dashboard-box :title="$t('report.endorsemet_report')">
            <template v-slot:preview>
              <slaughter-pie-chart-report
                :start-date.sync="filters.start_date"
                :end-date.sync="filters.end_date"
                :currency-rate-options="currencyRateOptions"
                :onClickButton="onClickButton"
                :filters.sync="tempFilters"
                @resizecard="resizeCard"
              ></slaughter-pie-chart-report>
            </template>
          </dashboard-box>
        </div>
        <div
          :class="[resizeChartValue == 'linechart' ? 'col-12' : 'col-6']"
          v-if="resizeChartValue != 'piechart'"
        >
          <dashboard-box :title="$t('report.endorsemet_report')">
            <template v-slot:preview>
              <slaughter-bar-chart-report
                :start-date.sync="filters.start_date"
                :end-date.sync="filters.end_date"
                :currency-rate-options="currencyRateOptions"
                :onClickButton="onClickButton"
                :filters.sync="tempFilters"
                chartData="chartData"
                @resizecard="resizeCard"
              ></slaughter-bar-chart-report>
            </template>
          </dashboard-box>
        </div>
      </div>

      <dashboard-box>
        <template v-slot:preview>
          <div @contextmenu.prevent="onContextMenu">
            <data-table
              :bordered="false"
              :borderless="true"
              :fields.sync="fields"
              :hover="false"
              :items="formattedReport"
              :no-border-collapse="true"
              :outlined="false"
              :striped="true"
              :disabled-infinite-loading="false"
              :infiniteId="infiniteId"
              :sticky-header="true"
              tableVariant="''"
              :page.sync="page"
              :perPage.sync="perPage"
              @hitBottom="onHitBottom"
              :sortBy.sync="order"
              :sortDesc.sync="sort"
              :responsive="true"
            ></data-table>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapMutations } from "vuex";
import moment from "moment";

// COMPONENTS
import DashboardBox from "@/assets/components/DashboardBox";
import DataTable from "@/assets/components/dataTable/DataTable"; 
import InputFilter from "@/assets/components/filters/InputFilter";
import SelectFilter from "@/assets/components/filters/SelectFilter";
import DateFilter from "@/assets/components/filters/DateFilter";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import SlaughterPieChartReport from "./SlaughterPieChartReport";
import SlaughterBarChartReport from "./SlaughterBarChartReport";

import {
  HANDLE_INFINITE_SCROLL,
  LOADING,
  SET_ITEMS,
  SET_LOADING,
  ITEMS,
  SET_FILTER,
  FILTER,
} from "@/core/services/store/report/slaughterReport.module";

import {
  GET_ITEMS,
  GET_ITEMS as REST_GET_ITEM,
} from "@/core/services/store/REST.module";
import {
  LARAVEL_DATE_FORMAT,
  MOMENT_SYSTEM_DATE_FORMAT,
} from "@/core/config/constant";
import * as _ from "lodash";
import ContextMenu from "@/assets/components/contextMenu/ContextMenu";
import {
  SLAUGHTER_REPORT_START_DATE_STORAGE_NAME,
  SLAUGHTER_REPORT_END_DATE_STORAGE_NAME,
} from "@/core/storage/storage-names"; 

export default {
  name: "SlaughterReport",
  components: {
    SelectFilter,
    InputFilter,
    DataTable,
    DashboardBox, 
    DateFilter,
    CustomMultiSelect,
    ContextMenu,
    SlaughterPieChartReport,
    SlaughterBarChartReport,
  },
  computed: {
    ...mapGetters({ 
      loading: LOADING,
      filter: FILTER,
      items: ITEMS,
    }),
    filters: {
      get: function () {
        return this.filter;
      },
      set: function (value) {
        this.setFilter(value);
      },
    },
    onClickResizeControl() {
      if (this.resizeBool != true) {
        return this.resize();
      } else {
        return;
      }
    },
    formattedReport() {
      if (
        !this.items ||
        this.items.data === null ||
        this.items.data === undefined
      )
        return [];
      let temp = []; 
      this.items.data.forEach((item) => {
 
        temp.push({
          order_number: item.order_number,
          company_name:item.company_name ?? '-',
          color:item.color ?? '-', 
          size_name : item.size_name ?? '-',
          size_amount : item.size_amount ?? '-',
          fabric_sent_kg : item.fabric_sent_kg +' Kg',
          fabric_sent_date :item.fabric_sent_date,
          supplier_name :item.supplier_name,
          teoric_grammage :item.teoric_grammage,
          teoric_slaughter_amount :item.teoric_slaughter_amount,
          slaughter_quantity :item.slaughter_quantity,
          reel_product_gram :item.reel_product_gram,
          loss :item.loss,
          order_size_and_color_sizes:item.order_size_and_color_sizes,
          sent_date:item.sent_date
        });
      });
      return temp;
    },
  },
  data() {
    return {
      // Filters & pagination
      infiniteId: 1,
      sort: "desc",
      order: "id",
      resizeChartValue: null,
      customerOptions: [],
      search: null,
      onClickButton: false,
      paginate: [],      
      team_id: [],
      page: 1,
      perPage: 25,
      startDate: moment().subtract(30, "d"),
      endDate: moment().add(1, "d"),
      reportListUrl: "api/report/slaughter-report",
      currencyRateOptions: [],
      companies: [], 
      representativeList: [], 
      totalData: [],
      tempFilters: {},
      order_id: [],
      fields: [
        {
          key: "order_number",
          label: this.$t("report.order_number"),
          sortable: true,
          class:
            "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg ",
        },
        {
          key: "company_name",
          label: this.$t("Müşteri"),
          sortable: true,
          class:
            "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg ",
        },
        {
          key: "color",
          label: this.$t("report.color"),
          sortable: true,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        }, 
        {
          key: "size_name",
          label: this.$t("report.size"),
          sortable: true,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "size_amount",
          label: this.$t("report.amount"),
          sortable: true,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "fabric_sent_kg",
          label: this.$t("Gönderilen Kumaş Kg."),
          sortable: true,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "sent_date",
          label: this.$t("Gönderi Tarihi"),
          sortable: true,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "supplier_name",
          label: this.$t("İmalatçı"),
          sortable: true,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "teoric_grammage",
          label: this.$t("Teorik Gramaj"),
          sortable: true,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "teoric_slaughter_amount",
          label: this.$t("Teorik Kesim Ad."),
          sortable: true,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "slaughter_quantity",
          label: this.$t("Reel Kesim Ad."),
          sortable: true,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "reel_product_gram",
          label: this.$t("Gerçek Ürün Gr."),
          sortable: true,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "loss",
          label: this.$t("Kayıp"),
          sortable: true,
          class:
            "text-center border border-top-0 border-right-0 font-size-lg p-0",
        },
      ], 
      represantative_id: [],
      company_id: [],
      orderOptions:[],
      teamList:[],
      chartData:[]
    };
  },
  methods: {
    resizeCard(val) {
      this.resizeChartValue = val;
    },
    ...mapMutations({
      setFilter: SET_FILTER,
    }),
    beforeFilterResult() {
      setTimeout(() => {
        this.filterResult();
      }, 100);
    },
    onContextMenu(event) {
      event.preventDefault();
    },
    filterResult() {
      localStorage.setItem(
        SLAUGHTER_REPORT_START_DATE_STORAGE_NAME,
        moment(this.filters.start_date)
      );
      localStorage.setItem(
        SLAUGHTER_REPORT_END_DATE_STORAGE_NAME,
        moment(this.filters.end_date)
      );
      let self = this;
      this.page = 1;
      this.filters.page = 1;
      this.infiniteId++;
      this.onClickButton = true;
      this.onHitBottom();
    },
    onHitBottom($state = null) {
      if (this.loading) {
        return;
      }
      let self = this;
      let filters = _.cloneDeep(this.filters);

      this.tempFilters = filters;
      this.$set(
        filters,
        "start_date",
        moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filters,
        "end_date",
        moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT)
      );

      this.$set(filters, "order", this.order);
      this.$set(filters, "sort", this.sort); 
      if (this.represantative_id) {
        this.$set(
          filters,
          "represantative_id",
          JSON.stringify(this.represantative_id)
        );
      }
      if (this.company_id) {
        this.$set(filters, "company_id", JSON.stringify(this.company_id));
      }
      if (this.order_id) {
        this.$set(filters, "order_id", JSON.stringify(this.order_id));
      }
      if (this.team_id) {
        this.$set(filters, "team_id", JSON.stringify(this.team_id));
      }

      self.$store
        .dispatch(HANDLE_INFINITE_SCROLL, {
          url: self.reportListUrl,
          filters: filters,
          $state: $state,
        })
        .then((result) => {
          this.onClickButton = false;
          if (result.status) {
            this.infiniteId++;
            if ($state) {
              $state.loaded();
            }
          } else {
            if ($state) {
              $state.complete();
            }
          }
        });
    },
    orderFilter(param){      
      let params=''; 
      if(param.team_id){
        params += '&team_id='+JSON.stringify(param.team_id);
      }
      if(param.company_id ){
        params += '&company_id='+JSON.stringify(param.company_id);
      }
        this.$store
          .dispatch(GET_ITEMS, {
            url: "api/report/order-list-packing?"+params, 
          })
          .then((result) => { 
            if (result.status) {
              this.orderOptions = result.data
            } else {
              console.log({ error: result });
            }
          });
    },
    resetFilters() {
      (this.sort = "desc"),
        (this.order = "id"),
        this.setFilter({
          page: 1,
          order_id: [],
          per_page: 25,
          search: "",
          start_date: moment().subtract(30, "d").format(LARAVEL_DATE_FORMAT),
          end_date: moment().add(1, "d").format(LARAVEL_DATE_FORMAT),
        });
      this.report_user_id = [];
      this.supplier_id = [];
      this.represantative_id = [];
      this.company_id = [];
    },
    setup() {
      let self = this,
        promises = [];

        
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/team-list",
        })
      );

      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/companies",
        })
      );

      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/representative-list",
        })
      );

      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/report/order-list-packing",
        })
      );       


      Promise.all(promises).then((results) => {
        if (results[0].status) { 
          self.teamList = self.convertArrayToObjectByKey(
            results[0].data,
            "id",
            "user_name"
          );
        }

        if (results[1].status) {
          self.customerOptions = self.convertArrayToObjectByKey(
            results[1].data,
            "id",
            "name"
          );
        } 

        if (results[2].status) {
          self.representativeList = self.convertArrayToObjectByKey(
            results[2].data,
            "id",
            "user_name"
          );
        }

        if (results[3].status) {
          self.orderOptions = results[3].data;
        } 
        
      });
    },
  },
  created() {
    this.$store.commit(SET_LOADING, false);
    this.$store.commit(SET_ITEMS, {});
  },
  mounted() {
    let self = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("general.dashboard"), route: "dashboard" },
      { title: this.$t("Kesim Raporu") },
    ]);

    if (!this.isUserGranted("EscTable", ["viewAny"])) {
      return false;
    }

    if (!this.filter) {
      this.resetFilters();
    }

    this.filters.end_date = localStorage.hasOwnProperty(
      SLAUGHTER_REPORT_END_DATE_STORAGE_NAME
    )
      ? moment(
          localStorage.getItem(SLAUGHTER_REPORT_END_DATE_STORAGE_NAME)
        )
      : moment().add(1, "d").format(LARAVEL_DATE_FORMAT);

    this.filters.start_date = localStorage.hasOwnProperty(
      SLAUGHTER_REPORT_START_DATE_STORAGE_NAME
    )
      ? moment(
          localStorage.getItem(SLAUGHTER_REPORT_START_DATE_STORAGE_NAME)
        )
      : moment().subtract(30, "d").format(LARAVEL_DATE_FORMAT);

    this.setup();
    this.onClickButton = true;
    setTimeout(this.filterResult, 500);
  },
  watch: {
    sort() {
      this.beforeFilterResult();
    },
    order() {
      this.beforeFilterResult();
    },
    "team_id"(value){ 
      this.orderFilter({'team_id':value})      
    },    
    "company_id"(value){ 
      this.orderFilter({'company_id':value})      
    },
  },
};
</script>

<style>
*:focus {
  outline: none;
}

#dashboard-card-body {
  padding-bottom: 5px !important;
}

.info-total-area {
  margin-bottom: 1%;
  margin-right: -1%;
  padding-right: 5%;
}
.info-total-area > div {
  flex: 0 0 17%; /* Kutuların genişliğini ayarlamak için istediğiniz değeri kullanabilirsiniz */
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  font-weight: 600;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.general-area {
  margin-bottom: 1%;
  padding-left: 2%;
}
.general-area > div {
  flex: 1; /* Kutuların genişliğini ayarlamak için istediğiniz değeri kullanabilirsiniz */
  text-align: center;
  font-weight: 600;
  font-size: 13px;
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.total-area-text {
  font-size: 13px;
  font-weight: 400;
}
</style>
<style lang="scss">
.b-table-sticky-header > .table.b-table > thead > tr > th {
  background-color: white !important;
}
.b-table-sticky-header {
  max-height: 500px;
}
.b-table-sticky-header > .table.b-table > tbody > tr > th {
  background-color: white !important;
}
</style>
