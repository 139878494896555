<template>
    <div>
      <button @click="changeChart()" class="btn btn-success btn-sm mb-3" > 
        {{ !change_chart ?  $t("Kesim Fire Grafiği") : $t("Ekip Kesim Fire Grafiği") }}
      </button>
      <p class="second-quality-title mb-0 pb-0 d-flex mx-3">
        {{  !change_chart ? $t("Ekip Bazında Ortalama Gramaj") : $t("Ay Bazında Ortalama Gramaj") }}
        <button-with-icon
          v-if="resizeBool == null || resizeBool == false"
          icon-name="icons.report.enlarge"
          class="button-open-close"
          size="sm"
          @onClick="resize()"
        ></button-with-icon>
        <button-with-icon
          v-if="resizeBool == true"
          icon-name="icons.report.reduce"
          class="button-open-close"
          size="sm"
          @onClick="resize()"
        ></button-with-icon>
      </p>
      <div
        class="row loading-area text-center justify-content-center align-items-center"
        v-if="chartLoading"
      >
        <div class="spinner-border text-secondary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div
        v-if="!chartLoading  && !change_chart"
        v-on:click="resizeBool ? null : resize()"
        :class="{ 'graphic-container': resizeBool }"
        class="mt-0 pt-0"
      >
        <apexchart
          type="bar"
          :height="height"
          :options="chartOptions"
          :series="series"
          :width="tmpWidth"
        ></apexchart>
      </div>

      <div
        v-if="!chartLoading && change_chart"
        v-on:click="resizeBool ? null : resize()"
        :class="{ 'graphic-container': resizeBool }"
        class="mt-0 pt-0"
      >
        <apexchart
          type="bar"
          :height="height"
          :options="chartOptionsTeam"
          :series="seriesTeam"
          :width="tmpWidth"
        ></apexchart>
      </div>
    </div>
  </template>
  <script>
  import { GET_ITEMS } from "@/core/services/store/REST.module";
  import VueApexCharts from "vue-apexcharts";
  import { mapGetters } from "vuex";
  import { LARAVEL_DATE_FORMAT } from "@/core/config/constant";
  import * as _ from "lodash";
  import moment from "moment";
  import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
  export default {
    name: "SlaughterBarChartReport",
    components: {
      apexchart: VueApexCharts,
      ButtonWithIcon,
    },
    data() {
      return {
        height: 250,
        width: null,
        chartCategories: [],
        tmpSeries: [],
        series: [],
        seriesTeam: [],
        chartLoading: false,
        resizeBool: false,
        getDataStatus: false,
        months: [
          {
            id: "01",
            name: this.$t("dateTime.january_full"),
          },
          {
            id: "02",
            name: this.$t("dateTime.february_full"),
          },
          {
            id: "03",
            name: this.$t("dateTime.march_full"),
          },
          {
            id: "04",
            name: this.$t("dateTime.april_full"),
          },
          {
            id: "05",
            name: this.$t("dateTime.may_full"),
          },
          {
            id: "06",
            name: this.$t("dateTime.june_full"),
          },
          {
            id: "07",
            name: this.$t("dateTime.july_full"),
          },
          {
            id: "08",
            name: this.$t("dateTime.august_full"),
          },
          {
            id: "09",
            name: this.$t("dateTime.september_full"),
          },
          {
            id: "10",
            name: this.$t("dateTime.october_full"),
          },
          {
            id: "11",
            name: this.$t("dateTime.november_full"),
          },
          {
            id: "12",
            name: this.$t("dateTime.december_full"),
          },
        ],
        change_chart:false,
        chartAxisTeam:[],        
      };
    },
    computed: {
      lineLength() {}, 
      chartOptions() {
        let seriesLength = 1;
        if (seriesLength <= 1) {
          seriesLength = 0.99;
        }
        if (this.resizeBool) {
          this.tmpWidth = window.innerWidth * seriesLength;
        } else {
          let widhMiniScreen = 0;
          if (window.innerWidth <= 1500) {
            widhMiniScreen = 2.8;
          } else {
            widhMiniScreen = 3.1;
          }
          this.tmpWidth = window.innerWidth / widhMiniScreen;
        }
        let self = this;
        let tmpChartOptions = {
          chart: {
            type: "bar",
            height: 300,
          },
          defaultLocale: "tr",
          colors: ["#572D80", "#4CAF50"],
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "55%",
              endingShape: "rounded",
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
          },
          xaxis: {
            categories: this.months.map((month) => month.name),
          },
          yaxis: {
            title: {
              text: "",
            },
          },
          fill: {
            opacity: 1,
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + self.$t("gr");
              },
            },
          },
        };
        return tmpChartOptions;
      },
      chartOptionsTeam() {
        let seriesLength = 1;
        if (seriesLength <= 1) {
          seriesLength = 0.99;
        }
        if (this.resizeBool) {
          this.tmpWidth = window.innerWidth * seriesLength;
        } else {
          let widhMiniScreen = 0;
          if (window.innerWidth <= 1500) {
            widhMiniScreen = 2.8;
          } else {
            widhMiniScreen = 3.1;
          }
          this.tmpWidth = window.innerWidth / widhMiniScreen;
        }
        let self = this;
        let tmpChartOptions = {
          chart: {
            type: "bar",
            height: 300,
          },
          defaultLocale: "tr",
          colors: ["#572D80", "#4CAF50"],
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "55%",
              endingShape: "rounded",
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
          },
          xaxis: {
            categories: this.chartAxisTeam,
          },
          yaxis: {
            title: {
              text: "",
            },
          },
          fill: {
            opacity: 1,
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + self.$t("gr");
              },
            },
          },
        };
        return tmpChartOptions;
      },
    },
    props: [
      "startDate",
      "endDate",
      "currencyRateOptions",
      "companyId",
      "orderId", 
      "filters",
      "onClickButton"
    ],
    mounted() {
      this.getItems();
    },
  
    methods: {
      changeChart(){
        this.change_chart = !this.change_chart
      },
      resize() {
        let seriesLength = 1;
  
        if (seriesLength <= 1) {
          seriesLength = 0.8;
        }
        this.resizeBool = !this.resizeBool;
        if (this.resizeBool) {
          this.height = 400;
          this.tmpWidth = window.innerWidth * seriesLength;
  
          this.$emit("resizecard", "linechart");
        } else {
          let widthMiniScreen = 0;
          if (window.innerWidth <= 1500) {
            widthMiniScreen = 2.8;
          } else {
            widthMiniScreen = 3.1;
          }
          this.height = 200;
          this.tmpWidth = window.innerWidth / widthMiniScreen;
          this.$emit("resizecard", null);
        }
      },
      getItems() {
        this.chartLoading = true;
        this.series = [];
        this.chartCategories = [];
  
        let filters = this.filters;
        this.$set(
          filters,
          "start_date",
          moment(this.startDate).format(LARAVEL_DATE_FORMAT)
        );
        this.$set(
          filters,
          "end_date",
          moment(this.endDate).format(LARAVEL_DATE_FORMAT)
        );
  
        this.$store
          .dispatch(GET_ITEMS, {
            filters: filters,
            url: "api/report/slaughter-bar-chart-report",
          })
          .then((result) => { 
            this.chartLoading = false;
            if (result.status) {
              let tmpData = result.data.series;
              let teoric = [];
              let reel = [];
              let self = this;
  
              this.months.forEach((element) => {
                let totalteoric = 0;
                let totalreel = 0;
                if(tmpData.length> 0){
                  _.forEach(tmpData, function (chartData, key) {
                    if (Number(element.id) == chartData.created_month) {
                      totalteoric += chartData.teoric_grammage;
                      totalreel +=  chartData.reel_grammage; 
                    }
                  }); 
                }
                teoric.push( Number(totalteoric).toLocaleString("en-EN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }));
                reel.push( totalreel.toLocaleString("en-EN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }));
              }); 
              this.series = [
                {
                  name: "Teorik Gramaj",
                  data: teoric,
                },
                {
                  name: "Gerçekleşen Gramaj",
                  data: reel,
                },
              ];  
              
              let teoricTeam = [];
              let reelTeam = [];
              let tmpDataTeam = result.data.teoric_reel_team_data;
              if(tmpDataTeam.length> 0){
                _.forEach(tmpDataTeam, function (chartData, key) {
                  self.chartAxisTeam.push(chartData.team_name)
                  teoricTeam.push( Number(chartData.teoric_grammage).toLocaleString("en-EN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }));
                  reelTeam.push( Number(chartData.reel_grammage).toLocaleString("en-EN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })); 
                });  
              }

              this.seriesTeam = [
                {
                  name: "Teorik Gramaj",
                  data: teoricTeam,
                },
                {
                  name: "Gerçekleşen Gramaj",
                  data: reelTeam,
                },
              ]; 
          }
          });
      },
    },
    watch: {
      onClickButton: function (newVal, oldVal) {
        if (newVal == true) {
          if (this.chartLoading != true) {
            this.series = [];
            this.labels = [];
            this.getItems();
          }
        }
      }, 
    },
  };
  </script>
  <style scoped>
  .second-quality-title {
    align-items: center;
    font-size: 14px;
    font-weight: 800;
    text-align: center;
    margin-top: 5px;
    color: #5cb7e0;
    justify-content: space-between;
  }
  .graphic-container {
    overflow-x: scroll;
    display: flex;
    position: relative;
  }
  .button-open-close {
    border: 0px !important;
    width: 20px;
  }
  .loading-area {
    height: 200px;
  }
  </style>
  